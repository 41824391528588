body {
    font-family: 'Libre Franklin', sans-serif;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: orange;
}

.toolbar {
    position: fixed;
    top: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    background-color: white;
}

.toolbar-element {
    display:block;
}

.toolbar-left-element {
    float: left;
}

.toolbar-right-element {
    float: right;
}

.img {
    min-height: 50px;
    height:50px;
    padding: 5px;
}

.button {
    font-size: 15px;
    margin: 5px 0px 5px 0px;
    padding: 5px;
    border-radius: 8px;
    transition: 0.4s;
    background-color: white;
    color:black;
}

.button:hover {
    background-color: orange;
    color:white;
    transition: 0.4s;
}

#sign-up {
    margin-right:30px;
}

.main-attraction {
    margin-top: 75px;
    margin-bottom: 25px;
    padding: 10px;
    text-align: center;
    background-color: white;
}

h1 {
    text-align: center;
    font-size: 55px;
}

p {
    text-align: center;
    font-size: 35px;
}

.button-container {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.advertisement {
    padding: 15px;
    margin-bottom: 45px;
}

#why {
    font-size: 42px;
}

.element {
    padding-top:3px;
    padding-bottom:3px;
}

.advertisement-list {
    list-style-type: disc;
    text-align: justify;
    font-size: 25px;
}

.photo {
    float: right;
    margin: 10px 0px 10px 10px;
    width: 50%;
}

.updates {
    margin: 15px 0px 15px 0px;
    padding: 5px;
    border-radius: 8px;
    border: 5px solid #ca643e;
    min-height: 100px;
    height: 150px;
    overflow: auto;
    background-color: white;
}

.updates h2 {
    font-size: 28px;
}

.updates li {
    font-size: 20px;
}

.bottom-photo {
    display: block;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 350px;
}
body {
  background-color: orange;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Libre Franklin, sans-serif;
}

.toolbar {
  background-color: #fff;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.toolbar-element {
  display: block;
}

.toolbar-left-element {
  float: left;
}

.toolbar-right-element {
  float: right;
}

.img {
  height: 50px;
  min-height: 50px;
  padding: 5px;
}

.button {
  color: #000;
  background-color: #fff;
  border-radius: 8px;
  margin: 5px 0;
  padding: 5px;
  font-size: 15px;
  transition: all .4s;
}

.button:hover {
  color: #fff;
  background-color: orange;
  transition: all .4s;
}

#sign-up {
  margin-right: 30px;
}

.main-attraction {
  text-align: center;
  background-color: #fff;
  margin-top: 75px;
  margin-bottom: 25px;
  padding: 10px;
}

h1 {
  text-align: center;
  font-size: 55px;
}

p {
  text-align: center;
  font-size: 35px;
}

.button-container {
  margin: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.advertisement {
  margin-bottom: 45px;
  padding: 15px;
}

#why {
  font-size: 42px;
}

.element {
  padding-top: 3px;
  padding-bottom: 3px;
}

.advertisement-list {
  text-align: justify;
  font-size: 25px;
  list-style-type: disc;
}

.photo {
  float: right;
  width: 50%;
  margin: 10px 0 10px 10px;
}

.updates {
  background-color: #fff;
  border: 5px solid #ca643e;
  border-radius: 8px;
  height: 150px;
  min-height: 100px;
  margin: 15px 0;
  padding: 5px;
  overflow: auto;
}

.updates h2 {
  font-size: 28px;
}

.updates li {
  font-size: 20px;
}

.bottom-photo {
  width: 50%;
  height: 350px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  display: block;
}

/*# sourceMappingURL=index.92050941.css.map */
